'use client';

import Link from 'next/link';
import { useEffect, useState } from 'react';
import Image from 'next/image';

const OmikujiLink = () => {
  const [omikujiLink, setOmikujiLink] = useState(true);

  const handlOmikujiLink = () => {
    setOmikujiLink(false);
  };

  const defaultBottom: number = 50;
  const [offsetBottom, setOffsetBottom] = useState<string>(`${defaultBottom}px`);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const windowWidth = window.innerWidth;

      let setHeight: number;

      switch (true) {
        case windowWidth <= 768:
          setHeight = 440;
          break;

        case windowWidth <= 1024:
          setHeight = 280;
          break;

        default:
          setHeight = 264;
          break;
      }

      const scrollPosition = window.scrollY + window.innerHeight;
      const limitHeight = document.documentElement.scrollHeight - setHeight;

      if (scrollPosition <= limitHeight) {
        setOffsetBottom(`${defaultBottom}px`);
      } else {
        const bottom = defaultBottom + scrollPosition - limitHeight;
        setOffsetBottom(`${bottom}px`);
      }
    });
  }, []);

  return (
    omikujiLink && (
      <div className={`fixed right-4 z-20 hover:rotate-12`} style={{ bottom: offsetBottom }}>
        <button className="relative z-10 ml-[60px]" onClick={handlOmikujiLink}>
          <Image src="images/omikuji_batsu.png" alt="閉じる" height={30} width={30} />
        </button>
        <Link href={{ pathname: '/omikuji/' }} className="relative top-[-15px] z-0">
          <Image src="images/omikuji_link.png" alt="おみくじへ" width={70} height={70} />
        </Link>
      </div>
    )
  );
};

export default OmikujiLink;
