import Link from 'next/link';
import classNames from 'classnames';

type Params = {
  href: string;
  text?: string;
  targetType?: string;
  className?: string;
};

function ReadMore(params: Params) {
  const { href, text = 'READ MORE', targetType = '_self', className = 'font-semibold after:border-b-txt05' } = params;

  const setClass: string = classNames(
    "inline-block w-auto after:bottom-0 after:block after:w-0 after:border-b-[1px] after:border-solid after:content-[''] after:[transition:all_0.3s_ease] hover:after:w-full",
    className,
  );

  return (
    <Link href={href} className={setClass} target={targetType}>
      {text}
    </Link>
  );
}

export default ReadMore;
